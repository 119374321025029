@keyframes showItemContent {
  from {
    transform: perspective(100em) rotateX(90deg);
  }

  to {
    transform: perspective(100em) rotateX(0deg);
    opacity: 1;
  }
}

section.charts {
  .section-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .chart-item {
    @include contentBox();
    transition: background-color 0.2s ease-in-out;
    height: calc(100% - 1rem);
    opacity: 0;
    animation-name: showItemContent;
    animation-fill-mode: forwards;
    animation-play-state: paused;

    &:hover {
      background-color: $mundus-agri-color-light;
    }

    &.chart-item-0 {
      animation-duration: 500ms;
      animation-delay: 100ms;
    }

    &.chart-item-1 {
      animation-duration: 500ms;
      animation-delay: 200ms;
    }

    &.chart-item-2 {
      animation-duration: 500ms;
      animation-delay: 300ms;
    }

    &.chart-item-3 {
      animation-duration: 500ms;
      animation-delay: 400ms;
    }

    &.chart-item-4 {
      animation-duration: 500ms;
      animation-delay: 500ms;
    }

    &.chart-item-5 {
      animation-duration: 500ms;
      animation-delay: 600ms;
    }
  }

  &.visible {
    .chart-item {
      animation-play-state: running;
    }
  }

  .section-item-inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
  }

  .item-image {
    display: none;

    @include breakpoint(480px) {
      flex: 1;
      margin: 0;
      height: auto;
      max-width: 50px;
      display: block;
    }

    @include breakpoint(600px) {
      max-width: 100px;
    }
  }

  .item-content {
    font-weight: bold;
    flex: 1;

    @include breakpoint(480px) {
      margin-left: 1rem;
    }
  }

  .item-title {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2rem;
    margin-bottom: 0;
  }

  .item-category {
    display: inline-block;
    padding: 0.1em 0.35em;
    background-color: $highlight-color;
    margin-top: 0.25rem;
    font-size: 0.75em;
    font-weight: normal;
    color: #fff;
  }

  .item-country {
    vertical-align: middle;

    img {
      margin-right: 0.15em;
      vertical-align: middle;

      &[src$="/.svg"] {
        display: none;
      }
    }

    img,
    span {
      vertical-align: middle;
    }
  }

  .item-details {
    margin-top: 0.5rem;
    font-weight: normal;
    font-size: 0.85rem;
    color: $body-font-color;
  }
}
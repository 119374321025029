.recently-registered-slider {
  .companies-slide {
    float: none;
    height: 100%;
    display: flex;
    color: $body-font-color;
    border: 1px solid $light-gray;
    background-color: $white;

    &>a {
      height: 100%;
      color: $body-font-color;
      padding: 1rem;
      overflow: hidden;
    }

    ul {
      margin: 0;
    }
  }

  .company-main {
    display: flex;
    overflow: hidden;
  }

  .company-name {
    flex: 1 1 100%;
  }

  .company-details {
    overflow: hidden;
    flex: 1 1 auto;

    hr {
      border-style: dotted;
    }

    .vas-banderole {
      margin-bottom: 0;
    }
  }

  .company-logo {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    margin-left: 1rem;
    flex: 0 0 30%;
    max-width: 30%;

    img {
      width: 100%;
      max-width: 100%;
      @include sharpen-image();
    }
  }

  .company-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }

  .company-address-1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .company-address-2 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.references-slider {
  .inner {
    height: 100%;
    padding: 1rem;
    background-color: $white;
    box-shadow: inset 0 0 0 1px $light-gray;
    text-align: center;
  }

  img {
    margin: auto;
    width: 100%;
    max-width: 140px;
    @include sharpen-image();
  }
}
#section-news-category {
  // .section-content {
  //   display: block;
  // }

  .section-item {
    display: block;
    // height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    @include imageDarkener(45%, 0.85);

    // &>a {
    //   display: block;
    // }
  }

  .item-content {
    width: 100%;
    position: absolute;
    padding: 1rem;
    bottom: 0;
    z-index: 2;
  }

  .item-title {
    color: $white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
  }
}
section.registration {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 4.5rem 0;

  .inner {
    position: relative;
    z-index: 2;
  }

  .section-headline {
    color: $mundus-agri-color;
    font-weight: bold;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($white, 0.4);
    top: 0;
    left: 0;
    z-index: 1;
  }
}

@media (max-width: 480px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-480px.jpg');
  }
}

@media (min-width: 481px) and (max-width: 680px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-680px.jpg');
  }
}

@media (min-width: 681px) and (max-width: 1000px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-1000px.jpg');
  }
}

@media (min-width: 1001px) and (max-width: 1400px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-1400px.jpg');
  }
}

@media (min-width: 1401px) and (max-width: 2000px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-1920px.jpg');
  }
}

@media (min-width: 2001px) {
  section.registration {
    background-image: url('~application/src/images/sections/registration/registration-bg-uhd.jpg');
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

section.top-news {
  display: block;

  .section-head {
    margin-top: 0;
    margin-bottom: 0;
  }

  .section-body {
    flex: 1;
  }

  .news {
    position: relative;
    color: $body-font-color;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    .news-category {
      color: $novella-agri-color;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    .news-info {
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      line-height: 1.1;
    }

    .news-provider {
      font-weight: bold;
    }

    @include newsBanderole();

    .news-image-resizer-16-9 {
      display: block;
    }

    .news-image-resizer-1-1 {
      display: none;
    }

    .news-headline {
      display: block;
      font-size: 1.25rem;
      margin-bottom: 0.25rem;
      line-height: 1.3em;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }

    .news-image {
      position: relative;
      display: block;
      flex: 1 1 100%;
      align-self: flex-start;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .news-body {
      flex: 1;
    }

    .news-teaser {
      font-size: 1rem;
    }

    @include breakpoint(mlarge) {
      flex-wrap: nowrap;

      .news-image {
        margin-right: 1.5rem;
        max-width: 275px;
        margin-bottom: 0;
      }
    }
  }
}
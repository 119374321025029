@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import '~application/node_modules/foundation-sites/scss/foundation';

@keyframes scaleWidth {
  0% {
    width: 0%;
    max-width: 0%;
  }

  100% {
    max-width: 100%;
  }
}

.product-click-stats {
  position: relative;
  min-height: 50px;

  .data-head {
    display: none;
    font-weight: bold;
    border-bottom: 1px dotted $medium-gray;

    &>div {
      padding: 0.5rem 0;
      flex: 1;
    }
  }

  .data-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0.25rem 0;
    border-bottom: 1px dotted $medium-gray;

    &:last-of-type {
      border-bottom: 0;
    }

    &>div {
      flex: 1 1 100%;
      padding: 0.15rem 0;

      &:nth-of-type(2) {
        padding-top: 0.75rem;
      }
    }
  }

  .button-row {
    text-align: center;
    padding-top: 1rem;
  }

  @include breakpoint(800px) {
    .data-head {
      display: flex;
    }

    .data-row {
      flex-wrap: nowrap;
      padding: 0;

      &>div {
        padding: 0.5rem 0;
        flex: 1;
      }
    }

    .data-head,
    .data-row {
      &>div {
        &:nth-of-type(1) {
          max-width: 250px;
        }

        &:nth-of-type(3) {
          text-align: right;
          min-width: 5rem;
          max-width: 5rem;
        }
      }
    }
  }

  .bar {
    position: relative;
    width: 0;
    max-width: 0%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    background-color: $chart-green-light;
    min-height: 15px;
    animation-name: scaleWidth;
    animation-duration: 500ms;
    animation-delay: 250ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    span {
      position: absolute;
      display: inline-block;
      bottom: 75%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;
      padding: 2px 5px;
      font-size: 0.75rem;
      line-height: 1;

      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-top-color: rgba(0, 0, 0, 0.75);
        border-width: 6px;
        margin-left: -6px;
      }
    }
  }

  .no-results-error {
    span {
      color: $medium-gray;
    }
  }
}
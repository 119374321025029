section.latest-news {
  margin-top: 1rem;

  .section-head {
    margin-top: 0;
    margin-bottom: 0;
  }

  .latest-news-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @include breakpoint(mlarge) {
      flex-wrap: nowrap;
    }
  }

  .col-left {
    position: relative;

    @include breakpoint(mlarge) {
      flex: 0 1 50%;
      max-width: 50%;
      margin-right: 0.75rem;
      padding: 0;
      order: 1;
    }

    @include breakpoint(large) {
      flex: 1 1 55%;
      max-width: 55%;
    }
  }

  .col-right {
    flex: 1 1 100%;

    @include breakpoint(mlarge) {
      flex: 1 1 50%;
      margin-left: 0.75rem;
      margin-bottom: 0;
      order: 2;
    }

    @include breakpoint(large) {
      flex: 1 1 37.5%;
    }

    .section-head {
      margin-top: 0;
    }
  }

  .news {
    position: relative;
    color: $body-font-color;
    margin: 0 0 1.5rem;
    overflow: hidden;
  }

  .news-category {
    color: $novella-agri-color;
    text-transform: uppercase;
    margin-top: 0.5rem;
    font-size: 0.9em;
  }

  .news-info {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 14px;
    line-height: 1.1;
  }

  .news-provider {
    font-weight: bold;
  }

  @include newsBanderole();

  .news-image-resizer-16-9 {
    display: block;
  }

  .news-image-resizer-1-1 {
    display: none;
  }

  .news-headline {
    display: block;
    font-size: 1.1em;
    line-height: 1.3em;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .news-image {
    position: relative;
    display: block;
    align-self: flex-start;
    background-color: $lighter-gray;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .news-teaser {
    font-size: 1em;
  }

  .news-container {
    display: flex;
    flex-wrap: wrap;
  }

  .highlighting {
    display: none;

    &.alt {
      display: inline-block;
    }
  }

  .primary-news {
    .news-teaser {
      display: block;
      font-size: 1em;
    }

    .news-category {
      margin-top: 1rem;
    }

    .highlighting {
      display: inline-block;

      &.alt {
        display: none;
      }
    }
  }

  @include breakpoint(mlarge) {
    .primary-news {
      .news {
        margin-bottom: 0;
      }

      .news-headline {
        font-size: 1.2em;
      }
    }

    .secondary-news,
    .tertiary-news {
      .news {
        flex-wrap: nowrap;
        font-size: 0.9em;
        margin-bottom: 1rem;

        .news-info {
          margin-top: 0.25rem;
          margin-bottom: 0.25rem;
        }

        .news-teaser {
          display: none;
        }
      }
    }

    .secondary-news {
      margin-top: 0;
      margin-bottom: 0.75rem;
      display: flex;
      justify-content: space-between;

      .news {
        max-width: calc(50% - 0.75rem);
        flex: 1 1 50%;
        flex-wrap: wrap;
        margin-bottom: 0;
      }

      .news-category {
        margin-top: 0.25rem;
      }

      .news-headline {
        margin-bottom: 0.25rem;
      }

      .news-image {
        max-width: 100%;
      }

    }

    .tertiary-news {
      .news {
        display: flex;
        flex-wrap: nowrap;
        flex: 1 1 100%;
        margin-bottom: 0.5rem;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        &:last-of-type {
          display: none;
        }

        .news-image {
          flex: 1 1 110px;
          max-width: 110px;
          min-width: 110px;
          margin-top: 0.25rem;
        }

        .news-body {
          flex: 1 1 auto;
          margin-left: 0.75rem;
        }

        .news-category {
          margin-top: 0.25rem;
        }

        .news-headline {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(large) {
    .secondary-news {
      .news {
        .news-teaser {
          display: block;
          line-height: 1.3;
        }
      }
    }

    .tertiary-news {
      .news {
        &:nth-last-of-type(1) {
          margin-bottom: 0.5rem;
        }

        &:last-of-type {
          display: flex;
        }
      }
    }
  }
}
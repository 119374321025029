@import '~application/src/scss/glide-slider/slider';

.references-slider {
  .inner {
    height: 100%;
    padding: 1rem;
    background-color: $white;
    box-shadow: inset 0 0 0 1px $light-gray;
    text-align: center;

    img {
      margin: auto;
      width: 100%;
      max-width: 140px;
      -webkit-backface-visibility: hidden;
      -ms-transform: translateZ(0); /* IE 9 */
      -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
      transform: translateZ(0);
    }
  }
}
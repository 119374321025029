@keyframes showItemContent {
  from {
    transform: perspective(100em) rotateX(90deg);
  }

  to {
    transform: perspective(100em) rotateX(0deg);
    opacity: 1;
  }
}

.responsibles .grid-margin-x>.cell {
   padding-bottom: 1rem;
   }
   
section.responsibles {
  .responsible {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: normal;
    margin-bottom: 10px;
    opacity: 0;
    height: 100%;
    
    .photo {
      max-width: 75px;
      flex: 0 1 auto;
      margin-right: 1rem;
      width: 100%;
    }

    .description {
      flex: 1 1 auto;
      display: flex;
    }

    @media(max-width: 453px) {
      .description {
        display: block;
      }
    }
  
    .details {
      flex: 1 1 auto;

      .responsibility {
        margin-bottom: 0.5rem;

        .title {
          font-size: 14px;
        }

        .location {
          font-size: 12px;
        }

        .products {
          font-size: 14px;
        }
      }
 
      .email span {
        font-size: 14px;
      }

      .phone span {
        font-size: 14px;
      }

     .fax span {
        font-size: 14px;
      }
    }

    .name {
      font-weight: bold;
    }

    @include breakpoint(large) {
      flex-wrap: nowrap;

      .photo {
        max-width: 115px;
      }
    }
  }
  
  .responsible-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media(max-width: 1352px) {
    .responsible-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media(max-width: 841px) {
    .description {
     display: flex;
     flex-direction: column;
    }
  }
  
  @media(max-width: 660px) {
    .responsible-row {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  .responsible-0 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
    margin-bottom: 0.625rem;
  }

  .responsible-1 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 100ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
    margin-bottom: 0.625rem;
  }

  .responsible-2 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 300ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
    margin-bottom: 0.625rem;
  }

  .responsible-3 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 500ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
    margin-bottom: 0.625rem;
  }

  .responsible-4 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 700ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-5 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 900ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-6 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 1100ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-7 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 1300ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-8 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 1500ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-9 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 1700ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  .responsible-10 {
    animation-name: showItemContent;
    animation-duration: 500ms;
    animation-delay: 1900ms;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }

  &.visible {
    .responsible {
      animation-play-state: running;
    }
  }
}

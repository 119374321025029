@import '~application/src/scss/glide-slider/slider';

.testimonials-slider {
  .glide {
    .glide__slides {
      margin: 0;
    }

    .glide__slide {
      padding: 0 1.5rem 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &>div {
        max-width: 600px;
        padding: 1rem;

        i.fas {
          font-size: 0.75rem;
          color: $mundus-agri-color-light;
        }

        @include breakpoint(medium) {
          padding: 1.5rem;

          i.fas {
            font-size: 2.5rem;
          }
        }

        p {
          font-size: 1rem;
        }
      }
    }

    .company-logo {
      margin: 0.5rem 0;
    }
  }
}
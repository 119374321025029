@import 'mixins';
@import 'variables';
@import 'settings';
@import '~application/node_modules/foundation-sites/scss/foundation';
@import 'index/latest-news';
@import 'index/top-news';
@import 'index/lower-special-anouncement';
@import 'index/section-news-category';
@import 'index/section-charts';
@import 'index/section-responsibles';
@import '~application/src/scss/glide-slider/testimonials-slider';
@import '~application/src/scss/glide-slider/references-slider';
@import '~application/src/scss/sections/registration';
@import 'index/login-info';
@import 'index/slider';

@import 'index/section-promo';

.section.analytics {
  .section-head {
    margin-bottom: 0;
  }

  .product-click-stats {
    flex: 1;
  }
}

section {
  margin-bottom: 2.5rem;

  .section-head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: $light-gray;
    padding: 0.5rem 1rem;
    margin: 0 0 0.5rem;

    &.shadow {
      @include boxShadow();
    }

    h2 {
      margin: 0;
      font-weight: bold;
    }

    @include breakpoint(420px) {
      align-items: center;
      padding: 0.75rem 1rem;
    }
  }

  .content-box {
    margin-bottom: 0;

    &.gray {
      background-color: lighten($light-gray, 5%);
    }
  }

  .section-icon {
    font-size: 1.85rem;
    font-weight: bold;
    margin-right: 0.75rem;

    i {
      transform: scaleX(-1);
      text-align: right;
    }
  }

  .section-title {
    flex: 1 1 auto;
    display: flex;
    margin-bottom: 0;

    i {
      flex: 0 1 auto;
      margin-top: 0.25rem;
      margin-right: 0.5rem;
    }

    @include breakpoint(small only) {
      font-size: 1.15rem;
    }

    @include breakpoint(420px) {
      flex: 1 1 auto;
      margin-right: 1.5rem;
    }
  }

  .section-nav {
    flex: 0 1 auto;
    white-space: nowrap;
    text-align: right;

    @include breakpoint(420px) {
      flex: 0 1 auto;
      margin-top: 0;
    }
  }

  &.dynamics,
  &.contracts,
  &.requests {
    .section-body>.grid-x>.cell {
      &:nth-of-type(4) {
        display: none;

        @include breakpoint(medium) {
          display: block;
        }

        @include breakpoint(large) {
          display: none;
        }
      }
    }

    .trade-item {
      height: calc(100% - 1rem);
    }
  }

  &.statistics,
  &.auditor {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.5rem);

    .content-box {
      flex: 1;

      &>div {
        height: 100%;
      }

      .auto {
        display: flex;
        flex-direction: column;
        width: auto;
      }

      .button {
        align-self: flex-start;
        margin-top: auto;
      }
    }

    .cell.img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

  }

  &.statistics {
    .cell.img {
      background-position: 55% 50%;
    }
  }

  &.auditor {
    .cell.img {
      background-image: url(https://www.mundus-agri.de/images/news/cache/24269_landscape_large.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 13% 50%;
      @include sharpen-image();
    }
  }

  &.logistics {
    .cell.img {
      background-image: url(~application/src/images/landingpage/logistics/logistics-header-680.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 25% 50%;
    }

    .cell.auto {
      display: flex;
      flex-direction: column;
    }

    .section-body {
      flex: 1;
      margin-bottom: 0;
    }
  }

  &.crop,
  &.logistics {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.5rem);

    .content-box {
      flex: 1;

      &>div {
        height: 100%;
      }

      .auto {
        display: flex;
        flex-direction: column;
        width: auto;
      }

      .button {
        align-self: flex-start;
        margin-top: auto;
      }
    }

    .cell.img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  &.crop {
    .cell.img {
      background-image: url('~mundus-agri/src/images/index/crop-data.jpg');
      background-position: center;

      @include breakpoint(large) {
        background-position: right center;
      }
    }
  }

  &.brokerage {
    .cell.img {
      background-image: url('~mundus-agri/src/images/index/brokerage.jpg');
      background-position: 20% center;
    }
  }

  &.topsellers,
  &.analytics {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2.5rem);

    .section-body {
      margin: 0;
    }

    .content-box {
      flex: 1;
    }
  }

  &.import-export {
    .canvas-wrap {
      margin-bottom: 1rem;

      @include breakpoint(mlarge) {
        margin-bottom: 0;
      }
    }

    canvas {
      display: block;
      width: 100%;
      height: 200px;
    }
  }

  &.about {
    margin: 2.5rem 0;
  }
}

ul.top-products-list {
  display: grid;
  margin-bottom: 1rem;

  li {
    margin-bottom: 0.25rem;
  }

  @include breakpoint(medium) {
    grid-template-columns: repeat(2, 1fr);
  }
}
section.promo {
  margin: 1rem 0;

  .banner {
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    padding: 1.5rem;
    min-height: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  svg {
    width: 100px;
    transform: rotate(-30deg);
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.25rem;
    color: $white;
    margin-bottom: 0.5rem;

    span {
      padding: 0.15rem 0.5rem;
      background-color: $primary-color;
    }

    b {
      font-family: Arial,Helvetica,sans-serif;
      font-weight: 900;
      text-transform: uppercase;
      color: #fcc923;
    }
  }

  p {
    color: $white;
    font-size: 1rem;

    span {
      padding: 0.15rem 0.25rem;
      background-color: $primary-color;
    }
  }

  .h5.alert {
    font-weight: 900;
  }

  .button {
    @include button-style(#fcb023, darken(#fcb023, 5%), #fff);
  }

  @include breakpoint(mlarge) {
    .banner {
      //padding: 1.5rem 2.5rem 1.5rem calc(125px + 2rem);
      background-position: bottom center;
    }

    svg {
      position: absolute;
      top: 1rem;
      left: 1rem;
      margin-bottom: 0;
    }

    h4 {
      font-size: 2.5rem;
    }


    p {
      font-size: 1.3rem;
    }

    svg {
      width: 125px;
    }
  }

  @include breakpoint(large) {
    .banner {
      background-size: 100%;
      transition: 1000ms background-size ease 100s;
    }
  }

  &.new-year {
    .banner {
      background-image: url('~application/src/images/abo/new-year-2022-promo-1920px.jpg');
    }
  }
  
  &.black-week {
    .banner {
      background-image: url('~application/src/images/abo/black-week-2022-promo-1920px.png');
    }
  }
}
.lower-special-anouncement {
  .banner {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 1.5rem;
    position: relative;
    display: flex;
    height: calc(100% - 1rem);
    min-height: 120px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    i {
      font-size: 2rem;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      color: $primary-color;
    }
  }

  h4 {
    font-size: 1.75rem;
    color: $white;
    margin-bottom: 0;

    span {
      padding: 0.15rem 0.5rem;
      background-color: $highlight-color;
    }
  }

  p {
    color: $white;
    font-size: 1.15rem;

    span {
      padding: 0.15rem 0.25rem;
      background-color: $highlight-color;
    }
  }
}
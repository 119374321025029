.glide {
  .glide__slides {
    margin: 0;
  }

  .glide__arrow {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0;
    background-color: $button-background;
    transition: $button-transition;
    border-radius: 0;
    width: 1.75em;
    height: 60px;
    opacity: 1;

    svg {
      fill: $white;
      width: 1.2em;
      height: 1.2em;
    }

    &:hover {
      background-color: $button-background-hover;
    }
  }

  .glide__arrow--left {
    left: 0;
    transform: translate(-55%, -50%);

    svg {
      transform: scaleX(-1);
    }
  }

  .glide__arrow--right {
    right: 0;
    transform: translate(55%, -50%);
  }
}